export const getColumns = (columnsType: ActionType) => {
  switch (columnsType) {
    case 'measurement':
      return {
        mobileColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'action', title: ' ' },
          { name: 'info_box', title: ' ' },
        ],
        mobileColumnsWidth: [
          { columnName: 'reference_number', width: 80 },
          { columnName: 'action', width: 120 },
          { columnName: 'info_box' },
        ],
        desktopColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'thickness', title: 'Thickness' },
          { name: 'deliveryTime', title: 'Delivery Time' },
          { name: 'readyForMeasurementsDate', title: 'Ready Measurements' },
          { name: 'action', title: ' ' }
        ],
        desktopColumnsWidth: [
          { columnName: 'reference_number', width: 140 },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'type', width: 180 },
          { columnName: 'thickness', width: 120 },
          { columnName: 'deliveryTime', width: 200 },
          { columnName: 'readyForMeasurementsDate', width: 200 },
          { columnName: 'action', width: 140 }
        ]
      };

    case 'internal_control':
      return {
        mobileColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'action', title: ' ' },
          { name: 'info_box', title: ' ' },
        ],
        mobileColumnsWidth: [
          { columnName: 'reference_number', width: 120 },
          { columnName: 'action', width: 80 },
          { columnName: 'info_box' },
        ],
        desktopColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'thickness', title: 'Thickness' },
          { name: 'deliveryTime', title: 'Delivery Time' },
          { name: 'timeSinceReady', title: 'Time since ready' },
          { name: 'readyForMeasurementsDate', title: 'Ready Measurements' },
          { name: 'delivery_date', title: 'Finish Production' },
          { name: 'action', title: ' ' }
        ],
        desktopColumnsWidth: [
          { columnName: 'reference_number', width: 140 },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'type' },
          { columnName: 'thickness', width: 120 },
          { columnName: 'deliveryTime', width: 130 },
          { columnName: 'timeSinceReady', width: 160 },
          { columnName: 'readyForMeasurementsDate', width: 190},
          { columnName: 'delivery_date', width: 160 },
          { columnName: 'action', width: 80 }
        ]
      };

    case 'prepare':
      return {
        mobileColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'action', title: ' ' },
          { name: 'info_box', title: ' ' },
        ],
        mobileColumnsWidth: [
          { columnName: 'reference_number', width: 120 },
          { columnName: 'action', width: 80 },
          { columnName: 'info_box' },
        ],
        desktopColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'thickness', title: 'Thickness' },
          { name: 'deliveryTime', title: 'Delivery Time' },
          { name: 'timeSinceReady', title: 'Time since ready' },
          { name: 'readyForMeasurementsDate', title: 'Ready Measurements' },
          { name: 'delivery_date', title: 'Finish Production' },
          { name: 'action', title: ' ' }
        ],
        desktopColumnsWidth: [
          { columnName: 'reference_number', width: 150 },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'type'},
          { columnName: 'thickness', width: 120 },
          { columnName: 'deliveryTime', width: 130 },
          { columnName: 'timeSinceReady', width: 160 },
          { columnName: 'readyForMeasurementsDate', width: 190},
          { columnName: 'delivery_date', width: 160 },
          { columnName: 'action', width: 80 }
        ]
      };

    case 'faults':
      return {
        mobileColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
        ],
        desktopColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
          { name: 'reason_text', title: 'Reason' },
          { name: 'comment_text', title: 'Comment' },
          { name: 'user', title: 'User' },
          { name: 'fault_at', title: 'Date' },
          { name: 'user_picker', title: 'Fault By', adminOnly: true },
          { name: 'cost', title: 'Cost' },
        ],
        desktopColumnsWidth: [
          { columnName: 'order_reference_number', width: 100 },
          { columnName: 'reference_number', width: 80 },
          { columnName: 'reason_text', width: 250 },
          { columnName: 'comment_text', wordWrapEnabled: true },
          { columnName: 'user', width: 100 },
          { columnName: 'fault_at', width: 140 },
          { columnName: 'user_picker', width: 140 },
          { columnName: 'cost', width: 100 }
        ]
      };

    case 'deviations':
      return {
        mobileColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
        ],
        desktopColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
          { name: 'image_table', title: 'Images' },
          { name: 'comment', title: 'Comment' },
          { name: 'user', title: 'User' },
          { name: 'created_at', title: 'Date' },
        ],
        desktopColumnsWidth: [
          { columnName: 'order_reference_number', width: 100 },
          { columnName: 'reference_number', width: 80 },
          { columnName: 'image_table', width: 120 },
          { columnName: 'comment', wordWrapEnabled: true },
          { columnName: 'user', width: 100 },
          { columnName: 'created_at', width: 140 },
        ]
      };

    case 'find':
      return {
        mobileColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
        ],
        desktopColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
          { name: 'types', title: 'Types' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'plate_number', title: 'Platenumber' },
          { name: 'thickness', title: 'Thickness' },
          { name: 'delivery_date', title: 'Finish Production' },
          { name: 'delivery_method', title: 'Packing Method' },
          { name: 'action', title: ' ' }
        ],
        desktopColumnsWidth: [
          { columnName: 'order_reference_number', width: 140 },
          { columnName: 'reference_number', width: 80 },
          { columnName: 'types', width: 400, wordWrapEnabled: true },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'type' },
          { columnName: 'plate_number', width: 120 },
          { columnName: 'thickness', width: 100 },
          { columnName: 'delivery_date', width: 140 },
          { columnName: 'delivery_method', width: 200, wordWrapEnabled: true },
          { columnName: 'action' }
        ]
      };
    
    case 'freight':
      return {
        mobileColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'plates', title: 'Plates' },
          { name: 'action', title: ' ' },
          { name: 'info_box', title: ' ' },
        ],
        mobileColumnsWidth: [
          { columnName: 'reference_number', width: 80 },
          { columnName: 'plates', width: 120 },
          { columnName: 'action' },
          { columnName: 'info_box' },
        ],
        desktopColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'plates', title: 'Plates' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'transport_company', title: 'Transport company' },
          { name: 'thickness', title: 'Thickness' },
          { name: 'delivery_date', title: 'Finish Production' },
          { name: 'delivery_method', title: 'Packing Method' },
          { name: 'action', title: 'Sending date' },
        ],
        desktopColumnsWidth: [
          { columnName: 'reference_number', width: 200 },
          { columnName: 'plates', width: 175 },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'transport_company', width: 170 },
          { columnName: 'thickness', width: 120 },
          { columnName: 'delivery_date', width: 130, wordWrapEnabled: true },
          { columnName: 'delivery_method', width: 200 },
          { columnName: 'action', width: 140 },
        ]
      };

    case 'plate':
    case 'packing':
      return {
        mobileColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
          { name: 'info_box', title: ' ' },
        ],
        mobileColumnsWidth: [
          { columnName: 'order_reference_number', width: 80 },
          { columnName: 'reference_number', width: 80 },
          { columnName: 'info_box' },
        ],
        desktopColumns: [
          { name: 'order_reference_number', title: 'Order' },
          { name: 'reference_number', title: 'Plate' },
          { name: 'types', title: 'Types' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'thickness', title: 'Thickness' },
          { name: 'delivery_date', title: 'Finish Production' },
          { name: 'delivery_method', title: 'Packing Method' },
          { name: 'action', title: ' ' }
        ],
        desktopColumnsWidth: [
          { columnName: 'order_reference_number', width: 100 },
          { columnName: 'reference_number', width: 80 },
          { columnName: 'types', width: 400, wordWrapEnabled: true },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'type' },
          { columnName: 'thickness', width: 100 },
          { columnName: 'delivery_date', width: 140 },
          { columnName: 'delivery_method', wordWrapEnabled: true },
          { columnName: 'action' }
        ]
      };

    case 'storage':
      return {
        mobileColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'plates', title: 'Plates' },
          { name: 'info_box', title: ' ' },
        ],
        mobileColumnsWidth: [
          { columnName: 'reference_number', width: 80 },
          { columnName: 'plates', width: 120 },
          { columnName: 'info_box' },
        ],
        desktopColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'plates', title: 'Plates' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'timeSinceReady', title: 'Time since ready' },
          { name: 'pickup_date', title: 'Date for pick up from SG' },
          { name: 'delivery_date', title: 'Finish Production' },
          { name: 'status', title: 'Status' },
          { name: 'delivery_method', title: 'Packing Method' },
          { name: 'action', title: ' ' },
        ],
        desktopColumnsWidth: [
          { columnName: 'reference_number', width: 200 },
          { columnName: 'plates', width: 175 },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'timeSinceReady', width: 120, wordWrapEnabled: true },
          { columnName: 'pickup_date', width: 130, wordWrapEnabled: true },
          { columnName: 'delivery_date', width: 130, wordWrapEnabled: true },
          { columnName: 'status', width: 120, wordWrapEnabled: true },
          { columnName: 'delivery_method', width: 200, wordWrapEnabled: true },
          { columnName: 'action', width: 140 },
        ]
      };

    case 'installation':
      return {
        mobileColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'plates', title: 'Plates' },
          { name: 'info_box', title: ' ' },
        ],
        mobileColumnsWidth: [
          { columnName: 'reference_number', width: 80 },
          { columnName: 'plates', width: 120 },
          { columnName: 'info_box' },
        ],
        desktopColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'plates', title: 'Plates' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'thickness', title: 'Thickness' },
          { name: 'timeSinceReady', title: 'Time since ready' },
          { name: 'pickup_date', title: 'Date for pick up from SG' },
          { name: 'delivery_date', title: 'Finish Production' },
          { name: 'delivery_method', title: 'Packing Method' },
          { name: 'action', title: ' ' },
        ],
        desktopColumnsWidth: [
          { columnName: 'reference_number', width: 200 },
          { columnName: 'plates', width: 175 },
          { columnName: 'name', wordWrapEnabled: true },
          { columnName: 'thickness', width: 120 },
          { columnName: 'timeSinceReady', width: 120, wordWrapEnabled: true },
          { columnName: 'pickup_date', width: 130, wordWrapEnabled: true },
          { columnName: 'delivery_date', width: 130, wordWrapEnabled: true },
          { columnName: 'delivery_method', width: 200, wordWrapEnabled: true },
          { columnName: 'action', width: 140 },
        ]
      };

    case 'completed':
      return {
        mobileColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'plates', title: 'Plates' },
        ],
        desktopColumns: [
          { name: 'reference_number', title: 'Order' },
          { name: 'is_invoiced', title: ' ' },
          { name: 'installation_date', title: 'Installation' },
          { name: 'plates', title: 'Plates' },
          { name: 'name', title: 'Material' },
          { name: 'type', title: 'Surface' },
          { name: 'thickness', title: 'Thickness' },
        ],
        desktopColumnsWidth: [
          { columnName: 'reference_number', width: 100 },
          { columnName: 'is_invoiced', width: 75 },
          { columnName: 'name', wordWrapEnabled: true }
        ]
      };

    case 'users':
      return {
        mobileColumns: [],
        desktopColumns: [
          { name: 'id', title: 'ID' },
          { name: 'username', title: 'Name' },
        ],
        desktopColumnsWidth: []
      };

    default:
      return {
        mobileColumns: [],
        desktopColumns: [],
        desktopColumnsWidth: []
      };
  }
};
